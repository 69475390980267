import LanSuGardenImage from "../../../../src/components/LanSuGardenImage";
import JapaneseGardenImage from "../../../../src/components/JapaneseGardenImage";
import PittockMansionImage from "../../../../src/components/PittockMansionImage";
import MultnomahFallsImage from "../../../../src/components/MultnomahFallsImage";
import HaystackRockImage from "../../../../src/components/HaystackRockImage";
import MtHoodImage from "../../../../src/components/MtHoodImage";
import MountSaintHelensImage from "../../../../src/components/MountSaintHelensImage";
import React from 'react';
export default {
  LanSuGardenImage,
  JapaneseGardenImage,
  PittockMansionImage,
  MultnomahFallsImage,
  HaystackRockImage,
  MtHoodImage,
  MountSaintHelensImage,
  React
};