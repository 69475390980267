module.exports = [{
      plugin: require('/Users/danmuzyka/danandprajwal/node_modules/gatsby-mdx/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/danmuzyka/danandprajwal/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.danandprajwal.com"},
    },{
      plugin: require('/Users/danmuzyka/danandprajwal/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
